import appConfigStoreModule from "@core/@app-config/appConfigStoreModule";
import Vue from "vue";
import Vuex from "vuex";
import app from "./app";
import user from "./user";
import admin from "./admin";
import _ from "lodash";


import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  getDocs,
  collection,
  serverTimestamp,
} from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    products: {
      marathon: {},
      guide: {},
    },
  },
  mutations: {
    setProducts(state, payload) {
      state.products = payload;
    },
    setMarathonDays(state, payload) {
      state.products.marathon[payload.marathonId].days = payload.days;
    },
  },
  actions: {
    async getMarathonDays({ commit, dispatch, state, rootState }, payload) {
      return new Promise(async function (resolve, reject) {
        let marathonDaysStorage = `${payload.marathonId}_days`
        if (localStorage[marathonDaysStorage]){
          resolve(JSON.parse(localStorage[marathonDaysStorage]))
        } else {
          if (!state.products.marathon[payload.marathonId]) {
            dispatch("getProducts", { uid: state.user.user.uid })
              .then(async (response) => {
                const db = getFirestore();
                let days = {};
                const querySnapshotDays = await getDocs(
                  collection(db, `products/${payload.marathonId}/days`)
                );
                querySnapshotDays.forEach((doc) => {
                  days[doc.id] = doc.data();
                  // doc.data() is never undefined for query doc snapshots
                });
                localStorage.setItem(
                  `${payload.marathonId}_days`,
                  JSON.stringify(days)
                );
                commit("setMarathonDays", {
                  days: days,
                  marathonId: payload.marathonId,
                });
                resolve(days);
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            if (state.products.marathon[payload.marathonId].days) {
              resolve(state.products.marathon[payload.marathonId].days);
            } else {
              const db = getFirestore();
              let days = {};
              const querySnapshotDays = await getDocs(
                collection(db, `products/${payload.marathonId}/days`)
              );
              querySnapshotDays.forEach((doc) => {
                days[doc.id] = doc.data();
                // doc.data() is never undefined for query doc snapshots
              });
              localStorage.setItem(
                `${payload.marathonId}_days`,
                JSON.stringify(days)
              );
              commit("setMarathonDays", {
                days: days,
                marathonId: payload.marathonId,
              });
              resolve(days);
            }
          }
        }
      });
    },
    async getProducts({ commit, dispatch, state }, payload) {
    return new Promise(async function (resolve, reject) {
      if (localStorage.products) {
        commit("setProducts", JSON.parse(localStorage.products));
        resolve(JSON.parse(localStorage.products));
      } else {
        const db = getFirestore();
        const querySnapshotProducts = await getDocs(collection(db, "products"));
        // let subscriptions = {};
        let products = {
          marathon: {},
          guide: {},
        };
        querySnapshotProducts.forEach((doc) => {
          let data = doc.data();
          data.id = doc.id;
          products[data.type][data.id] = data;
        });
        localStorage.products = JSON.stringify(products);
        commit("setProducts", products);
        resolve(products);
      }
    });
    },
    async getDayVideos({ commit, dispatch, state }, payload) {
      return new Promise((resolve, reject) => {
        if(state.products.marathon[payload.marathonId]) {
          if(state.products.marathon[payload.marathonId].days) {
            resolve(state.products.marathon[payload.marathonId].days[payload.dayNumber]);
          } else {
            dispatch("getMarathonDays", { marathonId: payload.marathonId })
            .then((response) => {
              resolve(response[payload.dayNumber]);
            })
          }
        } else {
          dispatch("getMarathonDays", { marathonId: payload.marathonId }).then(
            (response) => {
              resolve(response[payload.dayNumber]);
            }
          );
        }     
      });
    },
    async setDayCompleted({ commit, dispatch, state }, payload) {
      return new Promise(async function (resolve, reject) {
        const db = getFirestore();
        let dayData = {
          progress: {
            [payload.dayId]: {
              isDayCompleted: payload.isDayCompleted,
              isDayDifficult: payload.isDayDifficult,
            }
          }
        }
        if(payload.isDayCompleted) {
          dayData.progress[payload.dayId].completedAt = serverTimestamp()
        }
        const docRef = doc(db, `users/${state.user.user.uid}/subscriptions/${payload.marathonId}`);
        let update = await setDoc(docRef, dayData, { merge: true });
        console.log(dayData.progress)
        let updatedProgress = {
          ...state.user.user.progress,
          ...dayData.progress
        }
        commit('user/setUserProgress', updatedProgress)
        resolve(update)
      });
    },
    async getDayProgress({ commit, dispatch, state }, payload) {
      return new Promise(async function (resolve, reject) {
        if(state.user.user.progress) {
          resolve(state.user.user.progress)
        } else {
          const db = getFirestore();
          const docRef = doc(db, `users/${localStorage.uid}/subscriptions`, payload.marathonId);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            commit('user/setUserProgress', docSnap.data().progress)
            resolve(docSnap.data().progress);
          }
          else {
            reject('No progress recorded')
          }
        }
      });
    }
  },
  modules: {
    appConfig: appConfigStoreModule,
    app,
    user,
    admin,
  },
});
