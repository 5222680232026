import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  getDocs,
  collection,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

export default {
  namespaced: true,
  state: {
    marathons: {},
  },
  getters: {},
  mutations: {
    setMarathons(state, payload) {
      state.marathons = payload
    },
  },
  actions: {
    async getMarathons({ commit }, payload) {
      const db = getFirestore();
      // const docRef = doc(db, "marathons", payload.marathonId);
      const querySnapshot = await getDocs(collection(db, `marathons`));
      // const docSnap = await getDoc(docRef);
      let results = {};

      querySnapshot.forEach((doc) => {
        results[doc.id] = doc.data();
      });
      commit("setMarathons", results);
    },
  },
};
