import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store";
import { getAuth } from "firebase/auth";

Vue.use(VueRouter)

const routes = [
  // Admin Routes
  {
    path: "/admin",
    name: "admin-marathon",
    component: () => import("@/views/admin/AdminSettings.vue"),
    meta: {
      admin: true,
      layout: "content",
      protected: true,
    },
  },
  {
    path: "/",
    redirect: "home",
    meta: {
      protected: true,
    },
  },
  {
    path: "/home",
    name: "home",
    component: () => import("@/views/Home.vue"),
    meta: {
      layout: "content",
      protected: true,
      gtm: "Home",
    },
  },
  {
    path: "/marathon",
    name: "marathon",
    component: () => import("@/views/MarathonsHome.vue"),
    meta: {
      layout: "content",
      protected: true,
      gtm: "My Marathons",
    },
  },
  {
    path: "/marathon/:marathon_id",
    name: "marathonSingle",
    component: () => import("@/views/marathon/MarathonSingle.vue"),
    meta: {
      layout: "content",
      protected: true,
    },
  },
  {
    path: "/marathon/:marathon_id/day/:day_id",
    name: "daySingle",
    props: true,
    component: () => import("@/views/marathon/DaySingle.vue"),
    meta: {
      layout: "content",
      protected: true,
    },
  },
  {
    path: "/goals",
    name: "goals",
    component: () => import("@/views/Goals.vue"),
    meta: {
      layout: "content",
    },
  },
  {
    path: "/login",
    name: "auth-login",
    component: () => import("@/views/Login.vue"),
    meta: {
      layout: "blank",
      redirectIfLoggedIn: true,
      gtm: "Login",
    },
  },
  {
    path: "/register",
    name: "auth-register",
    component: () => import("@/views/Register.vue"),
    meta: {
      layout: "blank",
      resource: "Public",
      redirectIfLoggedIn: true,
      gtm: "Register",
    },
  },
  {
    path: "/forgot-password",
    name: "auth-forgot-password",
    component: () => import("@/views/ForgotPassword.vue"),
    meta: {
      layout: "blank",
      resource: "Public",
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "/error-404",
    name: "error-404",
    component: () => import("@/views/Error404.vue"),
    meta: {
      layout: "blank",
      resource: "Public",
    },
  },
  {
    path: "*",
    redirect: "error-404",
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

router.beforeEach((to, from, next) => {
  const auth = getAuth();
  const user = auth.currentUser;

  if (to.matched.some((record) => record.meta.protected)) {
    if (to.matched.some((record) => record.meta.admin)) {
      if (user.email == 'danielgundi@gmail.com') {
        next();
      } else {
        next({ name: "home" });
      }
    } else {
      if (user) {
        next();
      } else {
        if (to.query) {
          const params = new URLSearchParams(to.query);
          localStorage.redirecUri = `${to.path}?${params.toString()}`;
        } else {
          localStorage.redirecUri = to.path;
        }
        next({ name: "auth-register" });
      }
    }
  } else if (to.matched.some((record) => record.meta.redirectIfLoggedIn) && user) {
    if (localStorage.redirecUri) {
      next(localStorage.redirecUri);
      localStorage.removeItem("redirecUri");
    } else {
      next("home");
    }
  } else {
    next();
  }
}); 

export default router
