import { mdiFileOutline, mdiHomeOutline } from '@mdi/js'

export default [
  {
    title: "Дом",
    icon: mdiHomeOutline,
    to: "home",
  },
  {
    title: "Мои программы",
    icon: mdiFileOutline,
    to: "marathon",
  },
];
