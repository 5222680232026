<template>
  <v-menu
    offset-y
    left
    nudge-bottom="14"
    min-width="230"
    content-class="user-profile-menu-content"
  >

    <template v-slot:activator="{ on, attrs }">
      <v-badge
        bottom
        color="success"
        overlap
        offset-x="12"
        offset-y="12"
        class="ms-4"
        dot
      >
        <v-avatar
          size="40px"
          v-bind="attrs"
          color="primary"
          class="v-avatar-light-bg primary--text"
          v-on="on"
        >
          <v-img
            height="100%"
            :src="photoURL"
            v-if="photoURL"
          ></v-img>
          <template v-else>
            {{userInitials}}
          </template>
        </v-avatar>
      </v-badge>
    </template>
    <v-list>
      <div class="pb-3 pt-2">
        <v-badge
          bottom
          color="success"
          overlap
          offset-x="12"
          offset-y="12"
          class="ms-4"
          dot
        >
          <v-avatar
            size="40px"
            color="primary"
            class="v-avatar-light-bg primary--text"
          >
          <v-img
            height="100%"
            :src="photoURL"
            v-if="photoURL"
          ></v-img>
          <template v-else>
            {{userInitials}}
          </template>
          </v-avatar>
        </v-badge>
        <div
          class="d-inline-flex flex-column justify-center ms-3"
          style="vertical-align:middle"
        >
          <span class="text--primary font-weight-semibold mb-n1">
            {{displayName}}
          </span>
          <small class="text--disabled">{{userEmail}}</small>
        </div>
      </div>

      <v-divider></v-divider>

      <!-- Profile -->
      <v-list-item href="#" v-if="false">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiAccountOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Profile</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="my-2"></v-divider>
      <!-- Logout -->
      <v-list-item @click="logOut">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiLogoutVariant }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import {
  mdiAccountOutline,
  mdiEmailOutline,
  mdiCheckboxMarkedOutline,
  mdiChatOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
} from '@mdi/js'
import { getAuth, signOut } from "firebase/auth";
import { useRouter } from '@core/utils'
import { computed } from '@vue/composition-api'
import store from '@/store'

export default {
  setup() {
    const auth = getAuth();
    const { router } = useRouter()

    const logOut = () => {
      signOut(auth).then(() => {
       // Sign-out successful.
        router.push({ name: 'auth-login' })
      }).catch((error) => {
        // An error happened.
        console.log(error)
      });
    }

    const photoURL = computed(() => {
      if (store.state.user.user.photoURL !== '') return store.state.user.user.photoURL;
      return;
      // return require('@/assets/images/avatars/4.png')
    })
    const userInitials = computed(() => {
      const name = store.state.user.user.displayName.split(' ')
      return `${name[0].charAt(0)}${name[1] ? name[1].charAt(0) : ''}`;
    })
  
    const displayName = computed(() => {
      if (store.state.user.user.displayName !== '') return store.state.user.user.displayName;

      return store.state.user.user.email;
    })
    
    const userEmail = computed(() => {
      return store.state.user.user.email;
    })
    return {
      logOut,
      photoURL,
      displayName,
      userInitials,
      userEmail,
      icons: {
        mdiAccountOutline,
        mdiEmailOutline,
        mdiCheckboxMarkedOutline,
        mdiChatOutline,
        mdiCogOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
      },
    }
  },
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
