import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  getDocs,
  collection,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import fitnessCalculatorFunctions from "fitness-calculator";

export default {
  namespaced: true,
  state: {
    user: {
      uid: null,
      stripeId: "",
      stripeIdUAT: "",
      displayName: "",
      firstName: "",
      lastName: "",
      email: "",
      photoURL: "",
      gender: null,
      age: null,
      height: null,
      weight: null,
      activityLevel: "",
      bmi: 0,
      bmiCategory: "NA",
      activity: null,
      caloricNeeds: {
        balance: 0,
        mildWeightLoss: 0,
        mildWeightGain: 0,
        heavyWeightLoss: 0,
        heavyWeightGain: 0,
      },
      updatedAt: null,
      createdAt: null,
      lastLoginAt: null,
      // orders: [],
      subscriptions: {}
    },
  },
  getters: {
    user (state) {
      return state.user
    }
  },
  mutations: {
    setCurrentUser(state, payload) {
      state.user.uid = payload.uid ? payload.uid : "";
      state.user.stripeId = payload.stripeId ? payload.stripeId : "";
      state.user.stripeIdUAT = payload.stripeIdUAT ? payload.stripeIdUAT : "";
      state.user.email = payload.email ? payload.email : "";
      state.user.displayName = payload.displayName ? payload.displayName : "";
      state.user.photoURL = payload.photoURL ? payload.photoURL : "";
      state.user.createdAt = payload.createdAt ? payload.createdAt : "";
      state.user.lastLoginAt = payload.lastLoginAt ? payload.lastLoginAt : "";
      state.user.height = payload.height ? payload.height : null;
      state.user.weight = payload.weight ? payload.weight : null;
      state.user.bmi = payload.bmi ? payload.bmi : 0;
      state.user.bmiCategory = payload.bmiCategory ? payload.bmiCategory : "NA";
      state.user.age = payload.age ? payload.age : null;
      state.user.activity = payload.activity ? payload.activity : null;
      state.user.caloricNeeds = payload.caloricNeeds
        ? payload.caloricNeeds
        : {
            balance: 0,
            mildWeightLoss: 0,
            mildWeightGain: 0,
            heavyWeightLoss: 0,
            heavyWeightGain: 0,
          };
      state.user.subscriptions = payload.subscriptions ? payload.subscriptions : {};
      localStorage.setItem('uid',state.user.uid)
    },
    setUserAge(state, payload) {
      state.user.age = payload;
    },
    setUserGender(state, payload) {
      state.user.gender = payload;
    },
    setUserActivity(state, payload) {
      state.user.activity = payload;
    },
    setUserHeight(state, payload) {
      state.user.height = payload;
    },
    setUserWeight(state, payload) {
      state.user.weight = payload;
    },
    setBmi(state, payload) {
      state.user.height = payload.height;
      state.user.weight = payload.weight;
      state.user.bmiCategory = payload.bmiCategory;
      state.user.bmi = payload.bmi;
    },
    setUserProgress(state,payload) {
      state.user.progress = payload
    }
  },
  actions: {
    async getCurrentUser({ commit, dispatch }) {
      const db = getFirestore();
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        const docRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
           let subscriptions = {};
           const querySnapshotDays = await getDocs(
             collection(db, `users/${user.uid}/subscriptions`)
           );
           querySnapshotDays.forEach((doc) => {
             subscriptions[doc.id] = doc.data();
           });
          let userData = {
            ...docSnap.data(),
            uid: user.uid,
            lastLoginAt: user.metadata.lastLoginAt,
            subscriptions: subscriptions,
          };
          commit("setCurrentUser", userData);
          dispatch("getProducts", userData, { root: true });
        } else {
          let firstName;
          let lastName;
          if (user.displayName) {
            let nameSplit = user.displayName.split(" ");
            firstName = nameSplit[0];
            lastName = nameSplit[1];
          }
          let userDetails = {
            email: user.email ? user.email : "",
            displayName: user.displayName ? user.displayName : "",
            firstName: firstName ? firstName : "",
            lastName: lastName ? lastName : "",
            photoURL: user.photoURL ? user.photoURL : "",
            createdAt: user.metadata.createdAt ? user.metadata.createdAt : "",
            lastLoginAt: user.metadata.lastLoginAt
              ? user.metadata.lastLoginAt
              : "",
            orders: []
          };
          await setDoc(doc(db, "users", user.uid), userDetails);
          commit("setCurrentUser", user);
          dispatch("getProducts", user, { root: true });
        }
      }
    },
    async createNewUser({ commit }, payload) {
      const db = getFirestore();
      let userDetails = {
        email: payload.email,
        displayName: payload.displayName,
        createdAt: Math.floor(new Date().getTime() / 1000.0),
      };
      await setDoc(doc(db, "users", payload.uid), userDetails);
      return new Promise((resolve, reject) => {
        resolve();
      });
    },
    async getBmi({ commit, dispatch }, payload) {
      let bmiValue = fitnessCalculatorFunctions.BMI(
        parseInt(payload.height),
        parseInt(payload.weight)
      );
      let bmiCategory;
      switch (true) {
        case bmiValue < 18.5:
          bmiCategory = "underweight";
          break;
        case bmiValue > 18.5 && bmiValue < 24:
          bmiCategory = "healthy";
          break;
        case bmiValue > 24 && bmiValue < 30:
          bmiCategory = "overweight";
          break;
        case bmiValue > 30 && bmiValue < 35:
          bmiCategory = "obese-first";
          break;
        case bmiValue > 35 && bmiValue < 40:
          bmiCategory = "obese-second";
          break;
        case bmiValue > 40:
          bmiCategory = "obese-third";
          break;
      }

      dispatch("updateUserBmi", {
        height: payload.height,
        weight: payload.weight,
        bmiCategory: bmiCategory,
        bmi: bmiValue,
      });
    },
    async calculateUserInfo({ commit, dispatch }, payload) {
      const db = getFirestore();
      const auth = getAuth();
      const user = auth.currentUser;
      let caloricNeeds = await fitnessCalculatorFunctions.calorieNeeds(
        payload.gender,
        parseInt(payload.age),
        parseInt(payload.height),
        parseInt(payload.weight),
        payload.activity
      );

      let bmiValue = await fitnessCalculatorFunctions.BMI(
        parseInt(payload.height),
        parseInt(payload.weight)
      );
      let bmiCategory;
      switch (true) {
        case bmiValue < 18.5:
          bmiCategory = "underweight";
          break;
        case bmiValue > 18.5 && bmiValue < 24:
          bmiCategory = "healthy";
          break;
        case bmiValue > 24 && bmiValue < 30:
          bmiCategory = "overweight";
          break;
        case bmiValue > 30 && bmiValue < 35:
          bmiCategory = "obese-first";
          break;
        case bmiValue > 35 && bmiValue < 40:
          bmiCategory = "obese-second";
          break;
        case bmiValue > 40:
          bmiCategory = "obese-third";
          break;
      }

      let userData = {
        gender: payload.gender,
        age: parseInt(payload.age),
        height: parseInt(payload.height),
        weight: parseInt(payload.weight),
        activityLevel: payload.activity,
        bmiCategory: bmiCategory,
        bmi: bmiValue,
        caloricNeeds: caloricNeeds,
        updatedAt: Math.floor(new Date().getTime() / 1000.0),
      };

      if (user) {
        const docRef = doc(db, "users", user.uid);
        let update = await setDoc(docRef, userData, { merge: true });
        dispatch("getCurrentUser", update);
      }

      // dispatch("updateUserBmi", {
      //   height: payload.height,
      //   weight: payload.weight,
      //   bmiCategory: bmiCategory,
      //   bmi: bmiValue,
      // });

      // dispatch("updateUserCalorie", {
      //   caloricNeeds,
      // });
    },
    async updateUserBmi({ commit }, payload) {
      const db = getFirestore();
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        const docRef = doc(db, "users", user.uid);
        let update = await setDoc(docRef, payload, { merge: true });
        dispatch("getCurrentUser", update);
      }
    },
    async updateUserCalorie({ commit, dispatch }, payload) {
      const db = getFirestore();
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        const docRef = doc(db, "users", user.uid);
        let update = await setDoc(docRef, payload, { merge: true });
        dispatch("getCurrentUser", update);
      }
    },
  },
};
