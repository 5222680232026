import { mdiFileOutline, mdiHomeOutline } from '@mdi/js'

export default [
  {
    title: "Home",
    icon: mdiHomeOutline,
    to: "home",
  },
  {
    title: "Marathons",
    icon: mdiFileOutline,
    to: "marathon",
  },
];
